.vue-range-slider.slider-component {
  position: relative;
  box-sizing: border-box;
  user-select: none;
}
.vue-range-slider.slider-component .slider {
  position: relative;
  display: block;
  border-radius: 15px;
  background-color: #ccc;
}
.vue-range-slider.slider-component .slider::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.vue-range-slider.slider-component .slider .slider-dot {
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0,0,0,0.32);
  transition: all 0s;
  will-change: transform;
  cursor: pointer;
  z-index: 5;
}
.vue-range-slider.slider-component .slider .slider-dot.slider-dot-focus {
  box-shadow: 0 0 2px 1px #3498db;
}
.vue-range-slider.slider-component .slider .slider-dot.slider-dot-dragging {
  z-index: 5;
}
.vue-range-slider.slider-component .slider .slider-dot.slider-dot-disabled {
  z-index: 4;
}
.vue-range-slider.slider-component .slider .slider-dot.slider-hover:hover .slider-tooltip-wrap {
  display: block;
}
.vue-range-slider.slider-component .slider .slider-dot.slider-always .slider-tooltip-wrap {
  display: block !important;
}
.vue-range-slider.slider-component .slider .slider-process {
  position: absolute;
  border-radius: 15px;
  background-color: #3498db;
  z-index: 1;
}
.vue-range-slider.slider-component .slider .slider-process.slider-process-draggable {
  cursor: pointer;
  z-index: 3;
}
.vue-range-slider.slider-component .slider .slider-input {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}
.vue-range-slider.slider-component .slider .slider-piecewise {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  list-style: none;
}
.vue-range-slider.slider-component .slider .slider-piecewise .piecewise-item {
  position: absolute;
  width: 8px;
  height: 8px;
}
.vue-range-slider.slider-component .slider .slider-piecewise .piecewise-item:first-child .piecewise-dot,
.vue-range-slider.slider-component .slider .slider-piecewise .piecewise-item:last-child .piecewise-dot {
  visibility: hidden;
}
.vue-range-slider.slider-component .slider .slider-piecewise .piecewise-item .piecewise-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: rgba(0,0,0,0.16);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.3s;
}
.vue-range-slider.slider-component.slider-horizontal .slider-dot {
  left: 0;
}
.vue-range-slider.slider-component.slider-horizontal .slider-process {
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  will-change: width;
}
.vue-range-slider.slider-component.slider-vertical .slider-dot {
  bottom: 0;
}
.vue-range-slider.slider-component.slider-vertical .slider-process {
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  will-change: height;
}
.vue-range-slider.slider-component.slider-horizontal-reverse .slider-dot {
  right: 0;
}
.vue-range-slider.slider-component.slider-horizontal-reverse .slider-process {
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
}
.vue-range-slider.slider-component.slider-vertical-reverse .slider-dot {
  top: 0;
}
.vue-range-slider.slider-component.slider-vertical-reverse .slider-process {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
}
.vue-range-slider.slider-component.slider-horizontal .slider-piecewise .piecewise-item .piecewise-label,
.vue-range-slider.slider-component.slider-horizontal-reverse .slider-piecewise .piecewise-item .piecewise-label {
  position: absolute;
  display: inline-block;
  top: 100%;
  left: 50%;
  white-space: nowrap;
  font-size: 12px;
  color: #333;
  transform: translate(-50%, 8px);
  visibility: visible;
}
.vue-range-slider.slider-component.slider-vertical .slider-piecewise .piecewise-item .piecewise-label,
.vue-range-slider.slider-component.slider-vertical-reverse .slider-piecewise .piecewise-item .piecewise-label {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 100%;
  white-space: nowrap;
  font-size: 12px;
  color: #333;
  transform: translate(8px, -50%);
  visibility: visible;
}
.vue-range-slider.slider-component .slider-tooltip-wrap {
  display: none;
  position: absolute;
  z-index: 9;
}
.vue-range-slider.slider-component .slider-tooltip-wrap.merged-tooltip {
  display: block;
  visibility: hidden;
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-top {
  top: -9px;
  left: 50%;
  transform: translate(-50%, -100%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-top .slider-tooltip::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: inherit;
  transform: translate(-50%, 0);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-bottom {
  bottom: -9px;
  left: 50%;
  transform: translate(-50%, 100%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-bottom .slider-tooltip::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: inherit;
  transform: translate(-50%, 0);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-left {
  top: 50%;
  left: -9px;
  transform: translate(-100%, -50%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-left .slider-tooltip::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: inherit;
  transform: translate(0, -50%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-right {
  top: 50%;
  right: -9px;
  transform: translate(100%, -50%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.slider-tooltip-right .slider-tooltip::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: inherit;
  transform: translate(0, -50%);
}
.vue-range-slider.slider-component .slider-tooltip-wrap.merged-tooltip {
  display: block;
  visibility: hidden;
}
.vue-range-slider.slider-component .slider-tooltip-wrap .slider-tooltip {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #3498db;
  background-color: #3498db;
}
.vue-range-slider.slider-component.slider-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.vue-range-slider.slider-component.slider-disabled .slider-dot {
  cursor: not-allowed;
}
.vue-range-slider.slider-component.slider-has-label {
  margin-bottom: 15px;
}
